import $ from "jquery";
import 'bootstrap';
import dt from "datatables.net";
import dtbs from "datatables.net-bs5";
import * as toasts from "./toasts.js";
import bootbox from "bootbox";
import float from "js-float-calculation";

function ParseFloat(str,val) {
    str = str.toString();
    str = str.slice(0, (str.indexOf(".")) + val + 1); 
    return Number(str);   
}

const sl = {
    "emptyTable": "Nobenih podatkov ni na voljo",
    "info": "Prikazujem _START_ do _END_ od _TOTAL_ zapisov",
    "infoEmpty": "Prikazujem 0 do 0 od 0 zapisov",
    "infoFiltered": "(filtrirano od _MAX_ vseh zapisov)",
    "lengthMenu": "Prikaži _MENU_ zapisov",
    "loadingRecords": "Nalagam...",
    "search": "Išči:",
    "zeroRecords": "Nobeden zapis ne ustreza",
    "aria": {
        "sortAscending": "razvrsti naraščajoče",
        "sortDescending": "razvrsti padajoče"
    },
    "select": {
        "columns": {
            "1": "1 izbran stolpec",
            "_": "%d izbrani stolpci"
        },
        "rows": {
            "1": "1 izbrana vrstica",
            "_": "2 izbrane vrstice"
        },
        "cells": {
            "1": "1 izbrano polje",
            "_": "%d izbrana polja"
        }
    },
    "thousands": ".",
    "searchBuilder": {
        "conditions": {
            "array": {
                "equals": "Je enako",
                "empty": "Prazno",
                "contains": "Vsebuje",
                "not": "Ni",
                "notEmpty": "Ni prazno",
                "without": "Brez"
            },
            "string": {
                "endsWith": "Se konča z",
                "equals": "Je enako",
                "not": "Ni",
                "notEmpty": "Ni prazno",
                "startsWith": "Se začne z",
                "contains": "Vsebuje",
                "empty": "Prazno"
            },
            "number": {
                "notBetween": "Ni med",
                "notEmpty": "Ni prazno",
                "gt": "Večje kot",
                "gte": "Večje ali enako kot",
                "lt": "Manjše kot",
                "lte": "Manjše ali enako kot",
                "not": "Ni",
                "between": "Med",
                "empty": "Prazno",
                "equals": "Je enako"
            },
            "date": {
                "after": "Od",
                "before": "Do",
                "between": "Med",
                "empty": "Prazno",
                "equals": "Je enako",
                "not": "Ni enako",
                "notBetween": "Ni med",
                "notEmpty": "Ni prazno"
            }
        },
        "value": "Vrednost",
        "data": "Podatki",
        "deleteTitle": "Izbriši pravilo za filtriranje",
        "leftTitle": "Zamik v levo",
        "logicAnd": "in",
        "logicOr": "ali",
        "rightTitle": "Zamik v desno",
        "title": {
            "0": "Kriteriji iskanja",
            "_": "Kriteriji iskanja (%d)"
        },
        "button": {
            "_": "Kriteriji iskanja (%d)",
            "0": "Kriteriji iskanja"
        },
        "clearAll": "Izbriši vse",
        "condition": "Pogoj",
        "add": "Dodaj pogoj"
    },
    "searchPanes": {
        "clearMessage": "Izbriši vse",
        "collapse": {
            "0": "Iskalnik",
            "_": "Iskalnik (%d)"
        },
        "count": "{total}",
        "emptyPanes": "Ni vnosnega polja za iskanje",
        "loadMessage": "Nalagam iskalnik ...",
        "title": "Aktivni filtri - %d",
        "countFiltered": "{shown} ({total})"
    },
    "buttons": {
        "copySuccess": {
            "1": "1 vrstica prekopirana na odložišče.",
            "_": "%ds vrstic prekopiranih na odložišče."
        },
        "copyTitle": "Kopiraj na odložišče",
        "csv": "CSV",
        "excel": "Excel",
        "pageLength": {
            "-1": "Prikaži vse vrstice",
            "_": "Prikaži %ds vrstic"
        },
        "pdf": "PDF",
        "print": "Natisni",
        "colvis": "Vidni stolpci",
        "colvisRestore": "Ponastavi vidne stolpce",
        "copy": "Kopiraj",
        "copyKeys": "Pritisni Ctrl ali u2318 + C za kopiranje podatkov tabele v odložišče sistema. Za preklic klikni na to sporočilo ali pritisni Escape.",
        "collection": "Zbirka"
    },
    "decimal": ",",
    "infoThousands": ".",
    "paginate": {
        "first": "Prva",
        "last": "Zadnja",
        "next": "Naslednja",
        "previous": "Predhodna"
    },
    "processing": "Obdelujem ...",
    "autoFill": {
        "cancel": "prekliči",
        "fill": "napolni",
        "fillHorizontal": "napolni vodoravno",
        "fillVertical": "napolni navpično"
    },
    "datetime": {
        "previous": "Prejšnji",
        "next": "Naslednji",
        "hours": "Ure",
        "minutes": "Minute",
        "seconds": "Sekunde",
        "unknown": "Neznano",
        "amPm": [
            "am",
            "pm"
        ]
    },
    "editor": {
        "close": "Prekliči",
        "create": {
            "button": "Dodaj",
            "title": "Dodaj nov vnos",
            "submit": "Shrani"
        },
        "edit": {
            "button": "Uredi",
            "title": "Uredi vnos",
            "submit": "Shrani"
        },
        "remove": {
            "button": "Izbriši",
            "title": "Izbriši",
            "submit": "Izbriši",
            "confirm": {
                "_": "Si prepričan, da želiš izbrisati %d vrstic?",
                "1": "Si prepričan, da želiš izbrisati 1 vrstico?"
            }
        },
        "error": {
            "system": "Prišlo je do napake (Več informacij)."
        },
        "multi": {
            "title": "Več vrednosti",
            "restore": "Razveljavi spremembe",
            "info": "Izbrani elementi vsebujejo različne vrednosti za ta vnos. Če želite vse elemente za ta vnos urediti in nastaviti na enako vrednost, kliknite ali tapnite tukaj, sicer bodo ohranili svoje posamezne vrednosti.",
            "noMulti": "Ta vnos lahko urejate posamično, vendar ne del skupine."
        }
    }
}
function updateProgress(evt){
	
}
const uploadFile = function(file, fieldName, inputParent) {
	var vlogaId = $('#vloga').data('id');
	const API_ENDPOINT = "/evloge/vloga/"+vlogaId+"/upload?fieldname="+fieldName;
	const request = new XMLHttpRequest();
	const formData = new FormData();
	formData.append("file", file);
	
	request.upload.onload = () => {
		toasts.show({
			message:'Datoteka je bila uspešno naložena',
			color: 'success',
			time: 10000
		});
		var progressTimeout = setTimeout(function(){
			$('#progressbar').hide();
			$('#progressbar .bar').css('width', '0%');
		}, 1000)
	}
	request.upload.onerror = () => {
		toasts.show({
			message:'Napaka pri nalaganju datoteke',
			color: 'danger',
			time: 10000
		});
	}
	request.upload.onabort = () => {
		toasts.show({
			message:'Nalaganje preklicano',
			color: 'danger',
			time: 10000
		});
	}
	request.upload.onprogress = event => {
		$('#progressbar').show();
		if (event.lengthComputable) {
			var percentComplete = (event.loaded / event.total) * 100;  
			$('#progressbar .bar').css('width', percentComplete+'%');
		}
	}
	request.onreadystatechange = () => {
		if (request.readyState === 4 && request.status === 200) {
			var jsonData = JSON.parse(request.responseText);
			if(!jsonData.error){
				var numParents = inputParent.find('input[type="hidden"]').length;
				inputParent.append(`<div class="fileuploaded"><input type="hidden" name="${jsonData.fieldname}[${numParents}]" value="${jsonData.filename}">${jsonData.filename}  <span class="deletefile" data-file="${jsonData.filename}"></span></div>`);
				$('#save').trigger('click');
			} else {
				toasts.show({
					message:jsonData.error,
					color: 'danger',
					time: 10000
				});
			}
		}
	};
	request.open("POST", API_ENDPOINT, true);
	request.send(formData);
};

var unloadDefined = false;
var downloadTimer;

function setCookie(cname, cvalue, exdays) {
	const d = new Date();
	d.setTime(d.getTime() + (exdays*24*60*60*1000));
	let expires = "expires="+ d.toUTCString();
	document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
	let name = cname + "=";
	let decodedCookie = decodeURIComponent(document.cookie);
	let ca = decodedCookie.split(';');
	for(let i = 0; i <ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
}
function expireCookie(cookieName){
	if( getCookie( cookieName ) ) {
		document.cookie = cookieName + "=" +";expires=Thu, 01 Jan 1970 00:00:01 GMT";
	}
}

const beforeUnloadListener = (event) => {
	event.preventDefault();
	return event.returnValue = "Ste prepričani, da želite zapustiti urejanje?";
};

function checkValue(){
	$('.checkvalue').each(function(){
		if(parseInt($('#'+$(this).data('field1')).val().replace(',','.')) > parseInt($('#'+$(this).data('field2')).val().replace(',','.'))){
			$(this).text('Stroški presegajo vire: '+$('#'+$(this).data('field1')).val()+' € > '+$('#'+$(this).data('field2')).val()+' €').removeClass('text-success').addClass('text-danger');
		} else {
			$(this).text('Finančna konstrukcija je ustrezna.').removeClass('text-danger').addClass('text-success');
		}
	});
}

var IritationNum = 0;

function sumChanged(){
	var calc = false;
	$('input.sum').each(function(){
		var fields = $(this).data('fields').split(',');
		var avg = $(this).hasClass('avg');
		var cntFields = 0;
		let sumVal = parseFloat('0.00');
		fields.forEach(field => {
			if($('#'+field).length){
				cntFields++;
				let fieldValue = ($('#'+field).val() != '') ? parseFloat(String($('#'+field).val()).replace(',','.')).toFixed(2) : 0.00;
				if(fieldValue>0){
					$('#'+field).val(String(fieldValue).replace('.',','));
				} else {
					$('#'+field).val('0,00');
				}
				sumVal = sumVal.add(fieldValue);
			}
		});
		
		if(sumVal >= 0 && !isNaN(sumVal)){
			if(avg){
				sumVal = sumVal/cntFields;
				$(this).val(String(sumVal.toFixed(2)).replace('.',','));
			} else {
				$(this).val(String(sumVal.toFixed(2)).replace('.',','));
			}
			if($(this).data('type') == 'percent' && sumVal > 100){
				$(this).addClass('is-invalid');
			} else {
				$(this).removeClass('is-invalid');
			}
		}
	});
	checkValue();
}

/* function triggerFieldChangeSum(){
	$(document).on('change', 'input.number', function(){
		sumChanged();
	});
} */

function sumContainChanged(){
	$('input.sumcontains').each(function(){
		var containString = $(this).data('contains');
		let sumVal = parseFloat('0.00');
		var avg = $(this).hasClass('avg');
		if(avg){
			return; // enako delat kot continue v jquery.each, torej gremo samo po absolutnih seštevkih (po številih)
		}
		var cntFields = 0;
		$('input[id^="'+containString+'"]').each(function(){
			let field = $(this).attr('id');
			if($('#'+field).length){
				let fieldValue = ($('#'+field).val() != '') ? parseFloat(String($('#'+field).val()).replace(',','.')).toFixed(2) : 0.00;
				
				if(fieldValue>0){
					cntFields++;
					$('#'+field).val(String(fieldValue).replace('.',','));
				} else {
					$('#'+field).val('0,00');
				}
				sumVal = sumVal.add(fieldValue);
				
			}
		});
		if(sumVal >= 0 && !isNaN(sumVal)){
			$(this).val(String(sumVal.toFixed(2)).replace('.',','));
		}
		//console.log('sumContainChanged')
	});
	setAVGSum(); // tle gremo še po procentih
}

function setAVGSum(){
	$('input.sumcontains.avg').each(function(){
		var valueField = '';
		var idCelote = '';
		var numberPartValue = 0;
		
		if( $(this).attr('id').includes('procentskupaj') || $(this).attr('id').includes('znesekskupaj') ){ // viri
			idCelote = 'sredstvaskupajznesek';
			valueField = $(this).attr('id').replace('procent','znesek');
			if($(this).closest('td').prev().children('input')){
				numberPartValue = $(this).closest('td').prev().children('input').val();
			}else{
				numberPartValue = $(`#${valueField}`).val();
			}
		}else{ // načrt
			idCelote = 'porociloskupaj';
			valueField = $(this).attr('id').replace('procent','skupaj');
			numberPartValue = $(`#${valueField}`).val();
		}
		
		var partValue = parseFloat(String(numberPartValue).replace(',','.')).toFixed(2);
		if( partValue > 0 ){
			var sumFieldValue = parseFloat(String($(`#${idCelote}`).val()).replace(',','.')).toFixed(2);
			var percentage = ((partValue*100)/sumFieldValue);
			$(this).val(String(percentage.toFixed(2)).replace('.',','));
			if(percentage > 100){
				$(this).addClass('is-invalid');
			} else {
				$(this).removeClass('is-invalid');
			}
		}else{
			$(this).val('0,00');
		}
	});
}

/* function triggerFieldChangeSumContains(){
	$(document).on('change', 'input.number', function(){
		sumContainChanged();
	});
} */

function triggerFieldChangeMultiplayContains(){
	//$(document).on('change', 'input.number', function(){
		$('input.multiplay').each(function(){
			let multiplayValue = 0.00;
			let field1 = $(this).data('field1');
			let field1Value = ($('#'+field1).val() != '') ? parseFloat(String($('#'+field1).val()).replace(',','.')).toFixed(2) : 0;
			let field2 = $(this).data('field2');
			let field2Value = ($('#'+field2).val() != '') ? parseFloat(String($('#'+field2).val()).replace(',','.')).toFixed(2) : 0;
			multiplayValue = field1Value * field2Value;
			let tempValue = String(multiplayValue).replace('.',',');
			if(!isNaN(multiplayValue)){
				$(this).val(tempValue);
				sumChanged();
				sumContainChanged();
				//console.log('triggerFieldChangeMultiplayContains')
			}
		})
	//});
}
function triggerNumChange(){
	$(document).on('change', 'input.number', function(){
		triggerFieldChangeMultiplayContains()
		sumContainChanged();
		sumChanged();
		//console.log('Field changed!');
	});
}

function displayPodrocja(){
	$('input[name^="podrocje"]').on('click', function(){
		if($(this).is('[readonly]')){
			return false;
		} 
		if($(this).is(':checked')) {
			var vlogaId = $('#vloga').data('id');
			var type = $(this).data('id');
			var podrocjeWrapper = $('.financess_wrapper[data-type="'+type+'"]');
			var key = 0;
			$('.wrap_finance[data-type="'+type+'"]').each(function(e){
				key++;
			});
			$.get('/evloge/vloga/'+vlogaId+'/'+type+'', {'key':key,'showsection':true}, function(data){
				if(data != 'error'){
					podrocjeWrapper.append(data);
					triggerFieldChangeMultiplayContains();
					//$('.scroll .financess_wrapper[data-type="'+type+'"] .wrap_finance').last()[0].scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
				}
			});
			$('.dokazila_wrapper[data-type="'+type+'"]').addClass('show');
		} else {
			if(confirm('Želite izbrisati podatke področja? Postopek je dokončen')){
				$('.financess_wrapper[data-type="'+$(this).data('id')+'"] .wrap_finance').remove();
				$('.dokazila_wrapper[data-type="'+$(this).data('id')+'"]').removeClass('show');
				triggerFieldChangeMultiplayContains();
			} else {
				$(this).attr('checked',true);
			}
		}
	})
}

function getFormData($form){
	const data = new FormData($form[0]);
	const value = Object.fromEntries(data.entries());
	return value;

}

function save(){
	var disabled = $('#vloga input:disabled').removeAttr('disabled');
	var formData = JSON.stringify(getFormData($('#vloga')));
	disabled.attr('disabled','disabled');
	let vlogaID = $('#vloga').data('id');
	let userId = $('#vloga').data('userid');
	
	$.post('/evloge/vloga/'+vlogaID, { 'data':formData, 'userid':userId }, function(data){
		
		var jsonData = JSON.parse(data);
		if(jsonData.error == false){
			toasts.show({
				message:'Vloga začasno shranjena!'
			});
			if(unloadDefined){
				removeEventListener("beforeunload", beforeUnloadListener, {capture: true});
			}
			$(document).trigger('saved');
		}else{
			if(jsonData.userid){
				toasts.show({
					message:`NAPAKA<br>
								Prijavljen uporabnik se ne sklada z vlogo!<br>
								Zaradi varnosti podatkov vloga ni bila shranjena!
								<br><br>
								<b>Pri izpolnjevanju poročila je obvezno:<br />
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;da imate ta sistem odprt le v ENEM ZAVIHKU brskalnika in<br />
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;da ste v sistem prijavljeni le z ENIM UPORABNIŠKIM RAČUNOM na ENEM RAČUNALNIKU!</b><br />
								Za odpravo napake se morate odjaviti in ponovno prijaviti.<br />
								<br><br />
								Morebitne spremembe niso bile shranjene, zato si jih ročno shranite na vaš računalnik v beležnico!`,
					color: 'danger',
					time: 10000000
				});
			}else{
				toasts.show({
					message:`NAPAKA pri shranjevanju!`,
					color: 'danger',
					time: 10000000
				});
			}
		}
	})
}

function saveClickTrigger(){
	if($('#save').length){
		$('#save').on('click', function(e){
			e.preventDefault();
			save();
		})
	}
}

function timeSaveTrigger(){
	if($('#vloga').length){
		var interVal = setInterval(function(){
			$('#save').trigger('click');
		},60000);
	}
}

function checkIfPodrocjeExists(name, cnt){
	if($('input[name="'+name+'['+cnt+']"]').length === 0){
		return true;
	} else {
		for(var i = cnt; i < 50; i++){
			if($('input[name="'+name+'['+i+']"]').length == 0){
				return i;
			}
		}
	}
}

function addPodrocje(){
	$(document).on('click', '.addrecord', function(e){
		var vlogaId = $('#vloga').data('id');
		var type = $(this).data('type');
		var podrocjeWrapper = $('.financess_wrapper[data-type="'+type+'"]');
		var key = 1;
		$('.wrap_finance[data-type="'+type+'"]').each(function(e){
			key++;
		});
		let tempKey = checkIfPodrocjeExists(type, key);
		if(tempKey !== true){
			key = tempKey;
		}
		$.get('/evloge/vloga/'+vlogaId+'/'+type+'', {'key':key,'showsection':true}, function(data){
			if(data != 'error'){
				podrocjeWrapper.append(data);
				triggerFieldChangeMultiplayContains();
				
				//$('.scroll .financess_wrapper[data-type="'+type+'"] .wrap_finance').last()[0].scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
			}
		});
	});
}

function deletePodrocje(){
	$(document).on('click', '.deleterecord', function(e){
		var parentToRemove = $(this).parent().parent('.wrap_finance');
		var type = $(this).data('type');
		if(confirm('Želite izbrisati podatke področja? Postopek je dokončen')){
			parentToRemove.remove();
			if($('.financess_wrapper[data-type="'+type+'"] .wrap_finance').length == 0){
				$('input[name="podrocje['+type+']"]').prop( "checked", false );
				$('.dokazila_wrapper[data-type="'+type+'"]').removeClass('show');
				triggerFieldChangeMultiplayContains();
			}
			$('#save').trigger('click');
		}
	});
}

function defineUpload(){
	$(document).on("change", 'input[type="file"]', function(event){
		var inputParent = $(this).parent('.files_wrapper');
		const files = event.target.files;
		var fieldName = event.target.dataset.name;
		if(files[0].size < 3145728){
			uploadFile(files[0], fieldName, inputParent);
		} else {
			toasts.show({
				message:'Datoteka je prevelika, velikost je omejena na 3MB!',
				color: 'danger',
				time: 10000
			});
		}
	});
}

function defineDeleteFile(){
	$(document).on('click', '.deletefile', function(e){
		e.preventDefault();
		if(confirm("Želite izbrisati priloženo datoteko?")){
			var vlogaId = $('#vloga').data('id');
			var filename = $(this).data('file');
			var parentFile = $(this).parent('.fileuploaded');
			$.post('/evloge/vloga/'+vlogaId+'/delete/'+filename, function(data){
				var jsonData = JSON.parse(data);
				if(jsonData.filename){
					parentFile.remove();
					$('#save').trigger('click');
				} else {
					toasts.show({
						message:jsonData.error,
						color: 'danger',
						time: 10000
					});
				}
			});
		}
	})
}

function definePageUnload(){
	$('#vloga input').on('change', (event) => {
		if(!unloadDefined){
			addEventListener("beforeunload", beforeUnloadListener, {capture: true});
			unloadDefined = true;
		}
	});
}


function preveriDavcnoStevilko(davcnaStevilka) {
	// Preveri, ali je vnešena davčna številka dolga 8 ali 10 znakov
	if (davcnaStevilka.length !== 8 && davcnaStevilka.length !== 10) {
		return false;
	}
	
	// Preveri, ali je prvi del "SI", če ima 10 znakov
	if (davcnaStevilka.length === 10 && davcnaStevilka.substring(0, 2) !== 'SI') {
		return false;
	}
	
	// Odstrani "SI", če je prisoten
	const cistaDavcnaStevilka = davcnaStevilka.length === 10 ? davcnaStevilka.substring(2) : davcnaStevilka;
	
	// Preveri, ali so vse preostale znaki v nizu številske vrednosti
	if (!/^\d+$/.test(cistaDavcnaStevilka)) {
		return false;
	}
	
	// Preveri kontrolno število
	const kontrolnaSt = parseInt(cistaDavcnaStevilka[7]);
	const ostaliZnaki = cistaDavcnaStevilka.substring(0, 7);
	const vsota = ostaliZnaki
		.split('')
		.map(Number)
		.reduce((acc, curr, index) => acc + curr * (8 - index), 0);
	
	const preostanek = vsota % 11;
	const kontrolnaStevilka = preostanek === 0 ? 0 : 11 - preostanek;
	
	return kontrolnaSt === kontrolnaStevilka;
}

function searchVAT(){
	$('#getvatnumberdata').on('click', function(e){
		const davcna = $('#vatnumber').val();
		if(davcna != ''){
			
			if(davcna.includes(' ')){
				toasts.show({
					message: "Davčna številka ne sme imeti presledkov!",
					color: 'danger',
					time: 10000
				});
			}else{
				// preveri davcno
				if(preveriDavcnoStevilko(davcna)){
					
					$.get('/search/vat/'+davcna, function(data){
						var jsonData = JSON.parse(data);
						if(data.length > 5 && !jsonData.error){
							$('#registrationnumber').val(jsonData.xmlMaticnaStevilka);
							$('#name').val(jsonData.xmlNaziv);
							$('#address').val(jsonData.xmlNaslov);
							$('#trr').val(jsonData.xmlTransakcijskiRacuni.xmlTransakcijskiRacun.xmlTRR);
						} else {
							toasts.show({
								message:jsonData.error,
								color: 'danger',
								time: 10000
							});
						}
					});
				}else{
					toasts.show({
						message: "Neveljavna davčna številka",
						color: 'danger',
						time: 10000
					});
				}
			}
		}
	});
}


function startDownloadChecker(button, timeout) {
	var cookieName = "downloadDone";
	var downloadTimerAttempts = timeout;
	setCookie(cookieName, 0, downloadTimerAttempts);
	downloadTimer = window.setInterval(function () {
		var cookie = getCookie(cookieName);
		if ((typeof cookie === 'undefined' || cookie == '') || (downloadTimerAttempts == 0)) {
			button.attr('href', button.data('href'));
			button.html('Prenesi oddano vlogo <img class="ms-2" src="/assets/images/download-w.svg">');
			window.clearInterval(downloadTimer);
			expireCookie(cookieName);
		}
		downloadTimerAttempts--;
	}, 1000);
}

function downloadPDF(){
	$(".downloadpdf").on('click', function () {
		window.location.href=$(this).attr('href');
		var link = $(this).attr('href');
		$(this).data('href', $(this).attr('href')).attr('href', '#');
		$(this).html('Dokument se pripravlja');
		startDownloadChecker($(this), 120);
	});
}

function startDownloadCheckerIzjava(button, timeout) {
	var cookieName = "downloadDone";
	var downloadTimerAttempts = timeout;
	setCookie(cookieName, 0, downloadTimerAttempts);
	downloadTimer = window.setInterval(function () {
		var cookie = getCookie(cookieName);
		if ((typeof cookie === 'undefined' || cookie == '') || (downloadTimerAttempts == 0)) {
			button.attr('href', button.data('href'));
			button.html('Prenesi izjavo <img class="ms-2" src="/assets/images/download-w.svg">');
			window.clearInterval(downloadTimer);
			expireCookie(cookieName);
		}
		downloadTimerAttempts--;
	}, 1000);
}

function izjavaPDF(){
	$(".izjavapdf").on('click', function () {
		window.location.href=$(this).attr('href');
		var link = $(this).attr('href');
		$(this).data('href', $(this).attr('href')).attr('href', '#');
		$(this).html('Dokument se pripravlja');
		startDownloadCheckerIzjava($(this), 120);
	});
}

function setFilters(){
	$('.searchForm input, .searchForm select').on('change', function(e){
		var searchData = $('.searchForm').serialize();
		window.location.href =  $('.searchForm').data('url')+'?'+searchData;
	});
}

function validateForm(){
	$('.validateForm').on('click', function(e){
		e.preventDefault();
		$('.is-invalid').removeClass('is-invalid');
		$('.invalid-feedback').remove();
		var valid = true;
		$( ':input[required]', $('#vloga')).each(function(element){
			if(this.value.trim() == '' && $(this).parent().is(':visible')){ //&& !$(this).attr('readonly')
				valid = false;
				$(this).addClass('is-invalid');
				$(this).after(`<div class="invalid-feedback">Tega polja niste izpolnili. Če za vas ni relevantno (npr. tega ne izvajate), prosimo to označite s /.</div>`);
			}
		});
		if(!valid){
			toasts.show({
				message:'Izpolnite vsa polja!',
				color: 'danger',
				time: 10000
			});
			$('.is-invalid').first()[0].scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
		} else {
			$('#save').trigger('click');
			$(document).on('saved', function(e){
				window.location.href='/evloge/vloga/'+$('#vloga').data('id')+'/sign';
			});
		}
	});
	$( ':input[required]', $('#vloga')).on('change', function(){
		if(this.value.trim() != ''){
			$(this).removeClass('is-invalid');
			$(this).closest('.invalid-feedback').remove();
		}
	});
}

function signcert(){
	$('.signcert').on('click', function(e){
		e.preventDefault();
		var linkto = $(this).attr('href');
		bootbox.confirm({
			message: '<p>Želite oddati vlogo z digitalnim certifikatom?</p><p>V nadaljevanju vam bo spletna stran ponudila izbiro vašega digitalnega certifikata.</p>',
			title: 'Oddaja z digitalnim certifikatom',
			buttons: {
				confirm: {
					label: 'Da',
					className: 'btn-success'
				},
				cancel: {
					label: 'Ne',
					className: 'btn-primary'
				}
			},
			callback: function (result) {
				if(result){
					$('body').append('<div class="loading">Loading&#8230;</div>');
					window.location.href=linkto;
				}
			}
		});
	})
}

function signnocert(){
	$('.signnocert').on('click', function(e){
		e.preventDefault();
		var linkto = $(this).attr('href');
		bootbox.confirm({
			message: '<p>Želite oddati vlogo brez digitalnega certifikata?</p><p><b>Po oddaji boste preusmerjeni na seznam vlog, kjer boste lahko prenesli izjavo o oddaji vloge!</b></p>',
			title: 'Oddaja brez digitalnega certifikata',
			buttons: {
				confirm: {
					label: 'Da',
					className: 'btn-success'
				},
				cancel: {
					label: 'Ne',
					className: 'btn-primary'
				}
			},
			callback: function (result) {
				if(result){
					$('body').append('<div class="loading">Loading&#8230;</div>');
					window.location.href=linkto;
				}
			}
		});
	})
}

function triggerAnchorClick(){
	if($('#vloga').length){
		$(document).on('click', '.note', function(e){
			e.preventDefault();
			var elementID = $(this).data('load');
			var html = $('#'+elementID).html();
			if(typeof html !== 'undefined'){
				bootbox.alert(html);
			}
		})
	}
}
function autosize(that){
	setTimeout(function(){
		that.css({'height':'auto', 'padding':'18px 10px','min-height': that[0].scrollHeight + 'px'});
	},10);
}
function textareaExapnd() {
	if($('#vloga').length){
		$('textarea').on('keydown focus', function(){
			autosize($(this));
		});
	}
}

function addsubjectnumber(){
	$('.addsubjectnumber').on('click', function(e){
		e.preventDefault();
		let vlagatelj = $(this).data('vlagatelj');
		bootbox.prompt('Vpišite številko zadeve', function(result) {
			if(result != null){
				$.post('/eva/vlagatelji/subject', {'subjectnumber':result, 'vlagatelj':vlagatelj}, function(data){
					let jsonData = JSON.parse(data);
					if(jsonData.error == false){
						window.location.reload(true);
					} else {
						bootbox.alert('Prišlo je do napake pri urejanju št. zadeve!');
					}
				});
			}
		});
	})
}

function disableFieldsReport(){
	if($('#vloga').data('porocanje') == 1){
		$('.form-control, input[type="checkbox"]').not('.porocanje').attr('readonly',true);
		$(':checkbox[readonly]').on('click', function(e){
			e.preventDefault();
            return false;
        });
		$('.wrap_buttons').hide();
	}
}

function importFromSubmited(){
	$('.import').on('click', function(e){
		e.preventDefault();
		if($('select[name="report"]').val() != ''){
			toasts.show({
				message:'Pridobivamo vaše podatke!'
			});
			save();
			setTimeout(function(){
				$.post('/evloge/import/'+$('#vloga').data('id')+'/'+$('select[name="report"]').val(), function(data){
					var jsonData = JSON.parse(data);
					if(jsonData.error == false){
						toasts.show({
							message:'Podatki so bili uvoženi, spletna stran se bo osvežila z novimi podatki!'
						});
						window.location.reload();
					} else {
						bootbox.alert('Prišlo je do napake pri uvozu podatkov!');
					}
				});
			}, 600)
		} else {
			bootbox.alert('Izberite vlogo!');
		}
	});
}

function scrollBack(){
	if(document.getElementById("vloga")){
		const selector = '.col-md-9.scroll';
		if (localStorage.getItem("scrollPosition") != null) {
			$(selector).scrollTop(localStorage.getItem("scrollPosition"));
		}
		
		$(selector).on("scroll", function() {
			localStorage.setItem("scrollPosition", $(selector).scrollTop());
		});
	}else{
		localStorage.removeItem("scrollPosition");
	}
}

let passwordTimeout = null;
function togglePassword(){
	$(".toggle-password").on('click', function() {
		showHidePassword(this);
	});
	
	function showHidePassword(element){
		$(element).toggleClass("bi-eye");
		$(element).toggleClass("bi-eye-slash");
		
		var input = $($(element).attr("toggle"));
		if (input.attr("type") == "password") {
			input.attr("type", "text");
			toasts.show({
				message:"Geslo bo vidno 2 sekundi.",
				time: 2000
			});
			passwordTimeout = setTimeout(function(){
				showHidePassword(element)
			}, 2000)
		} else {
			clearTimeout(passwordTimeout);
			input.attr("type", "password");
		}
	}
}

$(function(){
	togglePassword();
	scrollBack();
	/* triggerFieldChangeSumContains();
	triggerFieldChangeSum();
	triggerFieldChangeMultiplayContains(); */
	triggerNumChange();
	triggerAnchorClick();
	textareaExapnd();
	displayPodrocja();
	saveClickTrigger();
	timeSaveTrigger();
	addPodrocje();
	deletePodrocje();
	defineUpload();
	definePageUnload();
	defineDeleteFile();
	searchVAT();
	downloadPDF();
	izjavaPDF();
	setFilters();
	validateForm();
	signcert();
	signnocert();
	// ADMIN
	addsubjectnumber();
	disableFieldsReport();
	importFromSubmited();
	$('[data-toggle="tooltip"]').tooltip();
});