import 'bootstrap';
export function show(config){
	var defaultSettings = {
		message: '',
		color: 'primary',
		time: 4000
	};
	var settings = {...defaultSettings, ...config}
	var template = `<div class="toast position-fixed align-items-center text-bg-${settings.color} border-0 toast fade show" role="alert" aria-live="assertive" aria-atomic="true">
						<div class="d-flex">
							<div class="toast-body">
							${settings.message}
							</div>
							<button type="button" class="btn-close btn-close-white me-2 m-auto close-toast" data-bs-dismiss="toast" aria-label="Close"></button>
						</div>
					</div>`;
	$('body').append(template);
	$(document).on('click','.close-toast',function(e){
		e.preventDefault();
		$('.toast').remove();
	});
	var removeToast = setTimeout(function(){
		$('.toast').remove();
	},settings.time);
}